import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["flow", "menu"];

  connect() {
    ReactRailsUJS.unmountComponents(".tabs__content");
  }

  disconnect() {
    ReactRailsUJS.unmountComponents(".tabs__content");
  }

  full(element) {
    this.menuTarget.classList.add("!h-full");
    ReactRailsUJS.unmountComponents(".tabs__content");
    ReactRailsUJS.mountComponents(".tabs__content");
  }

  fit(element) {
    this.menuTarget.classList.remove("!h-full");
    ReactRailsUJS.unmountComponents(".tabs__content");
    ReactRailsUJS.mountComponents(".tabs__content");
  }

  fitNoMount(element) {
    this.menuTarget.classList.remove("!h-full");
  }
}
