import { Controller } from 'stimulus'

export default class extends Controller {
	toggle(event) {
		const parentElement = event.target.closest('.boilerplate-card') // Assuming "boilerplate-card" is the parent container class
		const clicked = parentElement.querySelector("input[type='checkbox']")
		const checkboxes = this.element.querySelectorAll("input[type='checkbox']")

		if (clicked) {
			clicked.checked = !clicked.checked
			checkboxes.forEach((checkbox) => {
				if (checkbox !== clicked) {
					checkbox.checked = false
				}
			})
		}
	}
}
