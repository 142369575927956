import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["link"];

  async getAsync(_event) {
    await get(this.linkTarget.dataset.url, { responseKind: "turbo-stream" });
  }
}
