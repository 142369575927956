import Dropzone from 'dropzone'
import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import Rails from '@rails/ujs'
import { post } from '@rails/request.js'
import $ from 'jquery'

import { getMetaValue, toArray, findElement, removeElement, insertAfter } from './../helpers/index.js'

export default class extends Controller {
	static targets = ['input', 'clear']

	connect() {
		this.dropZone = createDropZone(this)
		this.noFiles = 0
		this.autoFiles()
		this.hideFileInput()
		this.bindEvents()
		Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
		// this.element.controller = this
	}

	autoFiles() {
		let files = this.inputTarget.getAttribute('data-files')
		if (files != undefined && files != null) {
			files = JSON.parse(files)

			for (let file of files) {
				file.status = 'success'
				this.dropZone.emit('addedfile', file)
				this.dropZone.emit('thumbnail', file, file.dataURL)
				this.noFiles += 1

				let directUploadController = new DirectUploadController(this, file)
				directUploadController.file.controller = directUploadController
				directUploadController.hiddenInput = directUploadController.createHiddenInput()
				directUploadController.hiddenInput.value = file.signed_id
				directUploadController.emitDropzoneSuccess()
			}
		}
	}

	// Private
	hideFileInput() {
		this.inputTarget.disabled = true
		this.inputTarget.style.display = 'none'
	}

	bindEvents() {
		const controller = this
		this.dropZone.on('addedfile', (file) => {
			setTimeout(() => {
				file.accepted &&
					createDirectUploadController(this, file).start(() => {
						let element = this.dropZone.element
						this.noFiles += 1
						if (controller.hasFiles && controller.hasClearTarget) controller.clearTarget.value = false
						if (element.getAttribute('data-dropzone-save') == 'true') {
							Rails.fire(element.closest('form'), 'submit')
						}
					})
			}, 500)
		})

		this.dropZone.on('removedfile', (file) => {
			file.controller && removeElement(file.controller.hiddenInput)
			this.noFiles -= 1
			if (!controller.hasFiles && controller.hasClearTarget) controller.clearTarget.value = true
		})

		this.dropZone.on('canceled', (file) => {
			file.controller && file.controller.xhr.abort()
		})

		this.dropZone.on('error', (file, error) => {
			let message
			let error_message
			let msgEl = $(file.previewElement).find('.dz-error-message')
			if (error.startsWith('File is too big')) {
				error_message = 'The image that you are trying to upload is bigger than 7MB. Please upload another image.'
				message = 'errors.big_file'
			} else {
				message = 'generic'
			}
			msgEl.show()
			msgEl.css('opacity', 1)
			const currentURL = window.location.href
			const baseURL = currentURL.split('/admin')[0] // Extract base URL

			const requestURL = `${baseURL}/admin/upload_error`

			post(requestURL, {
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
				body: JSON.stringify({
					message: 'upload_error',
				}),
			});

			setTimeout(() => {
				this.dropZone.removeFile(file)
			}, 3000)
		})
	}

	get hasFiles() {
		return this.noFiles > 0
	}

	get headers() {
		return { 'X-CSRF-Token': getMetaValue('csrf-token') }
	}

	get url() {
		return this.inputTarget.getAttribute('data-direct-upload-url')
	}

	get maxFiles() {
		return this.data.get('maxFiles') || 1
	}

	get maxFileSize() {
		return this.data.get('maxFileSize') || 256
	}

	get acceptedFiles() {
		return this.data.get('acceptedFiles')
	}

	get addRemoveLinks() {
		return this.data.get('addRemoveLinks') || true
	}
}

class DirectUploadController {
	constructor(source, file) {
		this.directUpload = createDirectUpload(file, source.url, this)
		this.source = source
		this.file = file
	}

	start(callback) {
		this.file.controller = this
		this.hiddenInput = this.createHiddenInput()
		this.directUpload.create((error, attributes) => {
			if (error) {
				removeElement(this.hiddenInput)
				this.emitDropzoneError(error)
			} else {
				this.hiddenInput.value = attributes.signed_id
				this.emitDropzoneSuccess()
				if (callback) callback()
			}
		})
	}

	createHiddenInput() {
		const input = document.createElement('input')
		input.type = 'hidden'
		input.name = this.source.inputTarget.name
		insertAfter(input, this.source.inputTarget)
		return input
	}

	directUploadWillStoreFileWithXHR(xhr) {
		// HACK: for working with DigitalOcean!
		xhr.setRequestHeader('x-amz-acl', 'public-read')
		this.bindProgressEvent(xhr)
		this.emitDropzoneUploading()
	}

	bindProgressEvent(xhr) {
		this.xhr = xhr
		this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event))
	}

	uploadRequestDidProgress(event) {
		const element = this.source.element
		const progress = (event.loaded / event.total) * 100
		findElement(this.file.previewTemplate, '.dz-upload').style.width = `${progress}%`
	}

	emitDropzoneUploading() {
		this.file.status = Dropzone.UPLOADING
		this.source.dropZone.emit('processing', this.file)
	}

	emitDropzoneError(error) {
		this.file.status = Dropzone.ERROR
		this.source.dropZone.emit('error', this.file, error)
		this.source.dropZone.emit('complete', this.file)
	}

	emitDropzoneSuccess() {
		this.file.status = Dropzone.SUCCESS
		this.source.dropZone.emit('success', this.file)
		this.source.dropZone.emit('complete', this.file)
	}
}

function createDirectUploadController(source, file) {
	return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
	return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
	return new Dropzone(controller.element, {
		url: controller.url,
		headers: controller.headers,
		maxFiles: controller.maxFiles,
		maxFilesize: controller.maxFileSize,
		acceptedFiles: controller.acceptedFiles,
		addRemoveLinks: controller.addRemoveLinks,
		autoQueue: false,
		clickable: '#custom-clickable',
	})
}
