// app/javascript/controllers/modal_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button"];

  connect() {}

  setLoading(e) {
    this.buttonTargets.forEach((button) => {
      button.classList.remove("tab-selected");
    });
    e.target.closest(".tab-disabled").classList.add("tab-selected");
    this.containerTarget.innerHTML = "";
    this.containerTarget.classList =
      "h-[300px] animate-skeleton rounded-lg bg-gray-light backdrop-blur-lg border border-gray-light";
  }
}
