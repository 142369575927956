import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  submit(event) {
    if (event.target.dataset.turboFrame) {
      this.formTarget.dataset.turboFrame = event.target.dataset.turboFrame;
    } else {
      this.formTarget.dataset.turboFrame = "_top";
    }
    this.element.requestSubmit();
  }
}
