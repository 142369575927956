import { Controller } from 'stimulus'
import { initFlowbite } from 'flowbite'

export default class extends Controller {
	connect() {
		initFlowbite()
	}

	disconnect() {}
}
