// app/javascript/controllers/topbar_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['mobileMenu', 'mobileButton']

	toggleMenu() {
		const isVisible = this.mobileMenuTarget.classList.contains('hidden')
		this.mobileMenuTarget.classList.toggle('hidden')
		this.mobileButtonTarget.classList.toggle('fa-bars', !isVisible)
		this.mobileButtonTarget.classList.toggle('fa-times', isVisible)
	}
}
