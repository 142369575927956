import React, { FC } from "react";
import { Badge } from "@theloopco/loopos-ds-sdk";

type MyComponentProps = typeof Badge extends FC<infer P> ? P : never;

const MyComponent: FC<MyComponentProps> = ({ text, variant, color }) => {
  return (
    <div>
      <Badge text={text} variant={variant} color={color} />
    </div>
  );
};

export default MyComponent;
