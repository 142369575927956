import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["button", "disable", "icon"]

  connect() {
  }

  load() {
    if (this.buttonTarget.classList.contains("action-button--danger")) {
      this.buttonTarget.previousElementSibling.classList.add('opacity-20')
    } else if (this.buttonTarget.classList.contains("action-button--success")) {
      this.buttonTarget.nextElementSibling.classList.add('opacity-20')
    }
    this.iconTarget.className = ""
    this.iconTarget.className = "fa-duotone fa-spinner-third fa-spin"
    
  }
}