// app/javascript/controllers/modal_controller.js

import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['modal']

	connect() {
		this.handleKeyDown = this.handleKeyDown.bind(this)
	}

	open(event) {
		event.preventDefault()

		this.toggleBodyOverflow(true)
		this.modalTarget.showModal()
		document.addEventListener('keydown', this.handleKeyDown)
	}

	close(event) {
		event.preventDefault()

		this.modalTarget.close()
		this.toggleBodyOverflow(false)
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	toggleBodyOverflow(disableScroll) {
		const body = document.body
		if (disableScroll) {
			body.style.overflow = 'hidden'
		} else {
			body.style.overflow = ''
		}
	}

	handleKeyDown(event) {
		if (event.key === 'Escape') {
			this.close(event)
		}
	}
}
