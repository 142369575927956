import { Controller } from 'stimulus'

export default class extends Controller {
	connect() {
		const accordionHeaders = document.querySelectorAll('.accordion__header')
		const accordionIcons = document.querySelectorAll('i[data-accordion-icon]')

		accordionHeaders.forEach((header, index) => {
			header.addEventListener('click', () => {
				const content = header.nextElementSibling

				const icon = accordionIcons[index]
				if (content.classList.contains('hidden')) {
					icon.classList.add('fa-plus')
					icon.classList.remove('fa-minus')
				} else {
					icon.classList.add('fa-minus')
					icon.classList.remove('fa-plus')
				}
			})
		})
	}
}
